@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Demi.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('fonts/MaisonNeue-Bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  font-family: "Maison Neue", "Source Sans Pro", sans-serif;
}

body {
  margin: 0;
  box-sizing: border-box;
  color: #1e3652;
	background: radial-gradient(#ecf1f4 50%, #dae3e8);
	min-height: 100vh;
}

:root {
	--dark-blue: #17697b;
	--med-blue: #299b9d;
	--light-blue: #50b3b1;
	--camel: #edd4a8;
	--sand: #e4aa27;
	--orange: #dc7231;
	--red: #d43b31;

	--cell-border: #e7edf1;
}

button,
.button {
	cursor: pointer;
}

input,
button {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	outline: none;
	border: 1px solid var(--cell-border);
	padding: 18px 15px 13px 15px;
	border-radius: 5px;
	transition: all 0.3s ease;
}

[contenteditable="true"] {
	outline: none;
}

[contenteditable="true"]:empty::before {
	content: attr(data-placeholder);
	opacity: 0.5;
}

[contenteditable="true"][data-after]::after {
	content: attr(data-after);
}

[contenteditable="true"][data-after]:empty::after {
	opacity: 0.5;
}

[contenteditable="true"][data-prefix]:not(:empty)::before {
	content: attr(data-prefix);
}

[contenteditable="true"][data-suffix]:not(:empty)::after {
	content: attr(data-suffix);
}

#root {
	text-align: center;
	max-width: 100vw;
	overflow: auto;
}

.app-wrapper {
	opacity: 0;
	transition: all 0.5s ease;
}

.app-wrapper.show {
	opacity: 1;
}

.button.button--add-button {
	position: absolute;
	width: 55px;
	bottom: 20px;
	right: 20px;
	padding: 31px 17px 24px 17px;
	border-radius: 50%;
	border: none;
	line-height: 0;
	font-size: 2em;
	font-weight: 300;
	box-shadow: 0px 5px 20px -10px #1e365280;
	transition: all 0.3s ease;
	cursor: pointer;
	outline: none;
}

.button--add-button.csv {
	padding: 29px 39px 26px 11px;
	font-size: 0.9em;
	font-weight: 500;
	width: 55px;
	right: 85px;
}

.button.button--add-button:hover {
	transform: scale(1.05);
	box-shadow: 0px 5px 20px -10px #1e3652;
}

.inactive--slide-out .add-sheet {
	opacity: 0;
}

.table-container {
	position: relative;
	display: inline-block;
	transition: all 0.3s ease;
}

.table-container.animate-in {
	opacity: 0;
	transform: translateY(50px);
}

.table-container.slide-left {
	/*-webkit-filter: blur(10px);*/
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	-webkit-transform: translate3d(0,0,0);
	backface-visibility: hidden;
	perspective: 1000;
}

.table.inactive {
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	background: #fff;
	z-index: 1;
	transition: all 0.3s ease;
	cursor: pointer;
}

.inactive.p1 { transform: scale(0.95); }
.inactive.p2 { transform: scale(0.90); }
.inactive.p3 { transform: scale(0.85); }
.inactive.p4 { transform: scale(0.80); }
.inactive.p5 { transform: scale(0.75); }
.inactive.p6 { transform: scale(0.70); }
.inactive.p7 { transform: scale(0.65); }
.inactive.p8 { transform: scale(0.60); }
.inactive.p9 { transform: scale(0.55); }
.inactive.p10 { transform: scale(0.50); }

.inactive.p1:hover { transform: scale(0.95) translate(-20px, 0); }
.inactive.p2:hover { transform: scale(0.90) translate(-20px, 0); }
.inactive.p3:hover { transform: scale(0.85) translate(-20px, 0); }
.inactive.p4:hover { transform: scale(0.80) translate(-20px, 0); }
.inactive.p5:hover { transform: scale(0.75) translate(-20px, 0); }
.inactive.p6:hover { transform: scale(0.70) translate(-20px, 0); }
.inactive.p7:hover { transform: scale(0.65) translate(-20px, 0); }
.inactive.p8:hover { transform: scale(0.60) translate(-20px, 0); }
.inactive.p9:hover { transform: scale(0.55) translate(-20px, 0); }
.inactive.p10:hover { transform: scale(0.50) translate(-20px, 0); }

.inactive.inactive--active-transition.p1:hover { transform: scale(0.95) translate(-100%, 0); }
.inactive.inactive--active-transition.p2:hover { transform: scale(0.90) translate(-100%, 0); }
.inactive.inactive--active-transition.p3:hover { transform: scale(0.85) translate(-100%, 0); }
.inactive.inactive--active-transition.p4:hover { transform: scale(0.80) translate(-100%, 0); }
.inactive.inactive--active-transition.p5:hover { transform: scale(0.75) translate(-100%, 0); }
.inactive.inactive--active-transition.p6:hover { transform: scale(0.70) translate(-100%, 0); }
.inactive.inactive--active-transition.p7:hover { transform: scale(0.65) translate(-100%, 0); }
.inactive.inactive--active-transition.p8:hover { transform: scale(0.60) translate(-100%, 0); }
.inactive.inactive--active-transition.p9:hover { transform: scale(0.55) translate(-100%, 0); }
.inactive.inactive--active-transition.p10:hover { transform: scale(0.50) translate(-100%, 0); }

.active.inactive--slide-in { transform: scale(1) translate(calc(-100% - 50px), 0); position: absolute; }
.active.inactive--slide-out {
	position: absolute;
	z-index: 5 !important;
	pointer-events: none;
}

.table {
	position: relative;
	margin: 100px 20px;
	box-shadow: 0px 10px 40px -10px #1e365240;
	display: inline-block;
	text-align: left;
	border-radius: 4px;
	background: #fff;
	height: calc(100vh - 204px);
	z-index: 20;
	overflow: auto;
	transition: all 0.3s ease;
	-webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
	max-width: 100vw;
}

.table > .sheet-name {
	position: absolute;
	width: calc(100% - 270px);
	bottom: 100%;
	left: 5px;
	top: 9px;
	padding: 10px;
	font-size: 1.3em;
	transition: all 0.3s ease;
	transition-property: font-size, font-weight;
}

.table .table--loading {
	position: absolute;
	top: 0;
	width: 100%;
	height: calc(100% - 103px);
	display: flex;
	justify-content: center;
	align-items: center;
	font-style: italic;
	opacity: 0.5;
	transition: all 0.3s ease;
}

.table .table--loading .loading--text {
	margin-top: 100px;
}

.table--loading.hide {
	opacity: 0;
	pointer-events: none;
}

.inactive > .sheet-name {
	transform: rotate(-90deg);
	top: calc(100% - 175px);
	width: 300px;
	left: -145px;
}

.inactive:hover {
	-webkit-filter: blur(0px) !important;
}

.inactive:hover .sheet-name {
	font-size: 2em;
	font-weight: 600;
	left: -145px;
}

.inactive > .sheet-name {
	opacity: 1;
}

.table .diagnostics {
	padding: 15px;
	background: #fff;
	text-align: right;
}

.diagnostics .spreadsheet-name {
	display: inline-block;
	float: left;
	font-size: 1.3em;
	transform: translateY(5px);
	font-weight: bold;
}

.button.button--small-label {
	border: 1px solid var(--cell-border);
	padding: 6px 5px 3px 5px;
	border-radius: 4px;
	color: #1e365280;
	text-transform: uppercase;
	font-size: 0.8em;
	letter-spacing: 0.5px;
	display: inline-block;
}

.button.button--blue {
	color: rgb(86, 172, 208);
	border-color: rgba(86, 172, 208, 0.25);
}

.dashboard-button {
	margin-right: 5px;
	outline: none;
}

.table > div:first-of-type {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.table > div:last-of-type {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.table > div:last-of-type .cell {
	border-bottom: none;
}

.table .row.header .cell-wrapper .cell > .MuiSvgIcon-root {
	position: absolute;
	height: 20px;
	vertical-align: top;
	margin-top: 13px;
	/* should be 15px but they're generally a bit less wide than 100%. */
	padding-left: 10px;
	left: 0;
}

.table .row.header .cell > span {
	padding-left: 40px;
	display: inline-block;
	width: calc(100% - 55px);
}

.table .row {
	transition: all 0.3s ease;
	background: #fff;
	position: relative;
	white-space: nowrap;
}

.table .row > * {
	white-space: initial;
}

.table .row .delete-row {
	position: absolute;
	right: 9px;
	padding: 10px;
	top: 3px;
	font-size: 1.5em;
	font-weight: 300;
	color: rgb(210, 116, 116);
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease;
	cursor: pointer;
}

.table .row:hover .delete-row,
.delete-row:hover {
	opacity: 1;
	pointer-events: all;
}

.table .row:not(.header):focus-within,
.table .row:not(.header).focus {
	box-shadow: 0px 5px 20px -5px #1e365250;
	z-index: 2;
	position: relative;
	border-radius: 4px;
}

.table .row:focus-within .cell {
	border-top: 1px solid transparent;
	border-bottom: 1px solid transparent;
}

.add-row button {
	width: 100%;
	border: none;
	padding: 18px;
	font-size: 1em;
	text-transform: uppercase;
	opacity: 0.5;
	letter-spacing: 0.7px;
	background: #f4f7fa;
	outline: none;
	color: inherit;
}

.table .cell {
	position: relative;
	top: 0;
	width: 100px;
	display: inline-block;
	vertical-align: top;
	height: 47px;

	border-color: #eee;

	border-top: 1px solid var(--cell-border);
	border-bottom: 1px solid var(--cell-border);
	margin-left: -1px;
	margin-top: -1px;
}

.table .row .cell.cell--action {
	width: 12px;
	padding: 0px 20px;
	transition: all 0.3s ease;
}

.table .row.header .cell.cell--action {
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
	font-weight: 300;
	padding: 14px 20px;
	height: 18px;
	border-top: 1px solid transparent;
}

.table .row.header .cell.cell--action:hover {
	border-top: 1px solid var(--cell-border);
	background: #f4f7fa;
	cursor: pointer;
}

.table .cell.cell--action.preview,
.table .row.header .cell.cell--action.preview {
	color: #1e365280;
	width: 20px;
	padding: 0 5px 0px 15px;
}

.table .row.header .cell.cell--action.preview {
	pointer-events: none;
}

.table .row .cell.cell--action.preview:hover {
	color: #1e3652;
	cursor: pointer;
}

/* -- decorators for computed properties, etc. -- */
.cell .cell--pill {
	font-size: 1em;
	border: 1px solid var(--cell-border);
	border-radius: 4px;
	padding: 4px 5px 1px 5px;
	margin-top: -5px;
	display: inline-block;
	transition: all 0.3s ease;
}

.cell .cell--label {
	font-size: 1em;
	border: 1px solid var(--cell-border);
	border-radius: 4px;
	padding: 4px 5px 1px 5px;
	margin-top: -5px;
	color: #1e3652c0;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	display: inline-block;
}

.table .cell > span {
	display: block;
	padding: 18px 15px 13px 15px;
	outline: none;

	overflow: hidden;
	white-space: nowrap;
	height: 16px;
}

.table .cell.expand > span {
	padding: 13px 15px 9px 15px;
}

.cell .cell--checkbox {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #e2e8ec;
	margin-top: -5px;
}

.cell label[tabindex] {
	outline: none;
}

.cell input[type="checkbox"] {
	display: none;
}

.cell input[type="checkbox"]:checked + .cell--checkbox {
	border-color: #1e3652;
}

.cell input[type="checkbox"] + .cell--checkbox span {
	display: inline-block;
	width: 20px;
	height: 20px;
}

.cell input[type="checkbox"]:checked + .cell--checkbox span {
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;

}

.table .cell[data-type="Long Text"] span {
	background: #fff;
}

.table .cell[data-type="Long Text"].expand {
	overflow: initial;
}

.cell--labels {
	cursor: pointer;
}

.cell--labels .cell--pill {
	margin-right: 5px;
}

.cell--labels .cell--pill:focus {
	border-color: #d4dfe6;
	background: var(--cell-border);
	outline: none;
}

.pseudo-focus::after {
	opacity: 0.5;
	content: "+";
	transition: all 0.3s ease;
}

.pseudo-focus:hover::after {
	opacity: 1;
}

.table .cell[data-type="Long Text"].expand > span,
.table .cell[data-type="Tags"].expand > span {
	position: relative;
	transform: translateY(-5px);
	height: 200px;
	box-shadow: 0px 10px 30px -10px #002a5b50;
	background: #fff;
	border-radius: 10px;
	width: calc(100% - 30px);
	white-space: normal;
	z-index: 1;
	transition: all 0.3s ease;
	line-height: 1.4;
	overflow: inherit;
}

.table .cell[data-type="Tags"].expand > span {
	height: 100px;
}


.table .cell[data-type="Tags"] span[contenteditable="true"] {
	display: inline-block;
	width: 5px;
	margin-left: 5px;
}

.cell[data-type="Rating"] .star {
	color: #ffcf57;
	transition: all 0.3s ease;
	cursor: pointer;
}

.table .cell[data-type="Rating"] .star {
	transform: translateY(-5px);
}


.cell[data-type="Rating"] .star--empty {
	opacity: 0;
}

.cell[data-type="Rating"]:hover .star--empty {
	opacity: 0.3;
}

.table .cell:focus {
	outline: none;
	z-index: 1;
	position: relative;
	border-color: #aaa;
}

.table .row div:first-of-type .cell span {
	padding-left: 30px;
}

.table .row.header {
	border-bottom: 2px solid var(--cell-border);
	border-top: 1px solid var(--cell-border);
	position: sticky;
	top: 0;
	z-index: 2;
	white-space: nowrap;
}

.table .row.header > * {
	white-space: nowrap;
}

.table .row.header .cell {
	border: none;
	font-weight: bold;
	white-space: nowrap;
}

.table .row .cell:not(.cell--action):hover {
	box-shadow: inset -4px 0px 0px -2px var(--cell-border);
}

.table .cell .resize-me {
	position: absolute;
	height: 100%;
	width: 10px;
	top: 0;
	right: -5px;
	padding: 0;
	cursor: col-resize;
}

.table .row.header .cell span:empty::before {
	content: attr(data-placeholder);
	opacity: 0.5;
}

.table .row.header .cell-wrapper {
	position: relative;
}

.formatted-text--input {
	display: inline-block;
	outline: none;
	margin: 0px 0px;
}

.formatted-text--label,
.formatted-text--input {
	text-transform: none;
	color: #1e3652;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;

	background: #1e365240;
	opacity: 0;
	pointer-events: none;

	transition: all 0.3s ease;
}

.overlay.show {
	opacity: 1;
	pointer-events: auto;
}

.information-sidebar {
	background: #fff;
	padding: 50px 50px 80px 50px;
	text-align: left;
	box-shadow: 0px 10px 50px -20px #1e3652a0;
	width: 350px;
	max-height: 90vh;
	overflow: auto;

	transform: scale(0.5);
	transition: all 0.3s ease;
}

.overlay.show .information-sidebar {
	transform: scale(1);
}

.overlay.show .information-sidebar.fade-out {
	opacity: 0;
	transform: scale(0.5);
}

.information-sidebar .row label {
	display: block;
	font-weight: bold;
	font-size: 0.8em;
	font-weight: 800;
	margin-bottom: 5px;
}

.information-sidebar .row .cell {
	border: 1px solid var(--cell-border);
	display: block;
	border-radius: 4px;
	padding: 18px 15px 13px 15px;
	margin: 20px 0px;
}

.information-sidebar .row .cell[data-type="Checkbox"] {
	border: none;
	width: auto;
	padding: 0;
	margin-left: 16px;
}

.information-sidebar .row .cell[data-type="Checkbox"] label {
	margin-top: 10px;
}

.information-sidebar .cell > span {
	height: 16px;
	display: inline-block;
	width: 100%;

	text-align: left !important;
}

.information-sidebar .delete-row {
	border: 1px solid #d2747480;
	color: #d27474;
	border-radius: 4px;
	padding: 20px;
	font-size: inherit;
	padding: 9px 9px 6px 9px;
	float: right;
	position: absolute;
	bottom: 50px;
	right: 50px;
}

.information-sidebar h1 {
	margin: 0;
}

.information-sidebar h1 + p {
	opacity: 0.7;
	font-size: 0.9em;
	margin-top: 5px;
}

/* -- read only table -- */
.table--read-only {

}

/* -- notebooks -- */
.notebook-utils {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 2;
}

.notebook-utils .button.back-to-notebooks {
	border-color: #cad5da;
	vertical-align: top;
	margin-top: 2px;
}

.notebook-utils .notebook-name {
	display: inline-block;
	padding: 0px 20px;
	height: 32px;
	font-size: 2em;
	font-weight: bold;
	text-align: left;
}

.notebooks {
	position: absolute;
	top: 0;
	width: 80vw;
	padding: 100px 10vw;
	height: calc(100vh - 200px);
	text-align: left;
	transition: all 0.3s ease;
	z-index: 21;
}

.notebooks.collapse {
	top: calc(100% - 365px);
	height: 275px;
	padding: 70px 20px 20px 20px;
	width: calc(100% - 40px);
	white-space: nowrap;
}

.notebooks.collapse.hide {
	transform: translateY(275px);
	height: 0;
	overflow: auto;
}

.notebooks.collapse.hide:hover {
	transform: translateY(0);
	background: #ffffff;
	height: 275px;
}

.notebooks > h1 {
	margin: 0 0 0 1%;
	text-align: left;
	font-size: 3em;
	letter-spacing: -2px;
	height: 0;
	transform: translateY(-40px);
	transition: all 0.3s ease;
}

.notebook {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 18vw;
	height: 23.3vw;
	box-shadow: 0px 10px 40px -10px #1e365240;
	margin: 1vw;
	cursor: pointer;
	transition: all 0.3s ease;
}

.notebook .active-badge {
	color: #47fe91;
	border-color: #59ff9c80;
	position: absolute;
	top: 15px;
	left: 15px;
	z-index: 2;
	background: #00000040;
}

.notebook:hover {
	transform: scale(1.02);
}

.notebooks.collapse .notebook {
	width: 213px;
	height: 275px;
	margin: 0px 10px;
}

.notebooks.collapse > h1 {
	transform: translate(calc(0px), -40px);
	font-size: 2em;
	letter-spacing: -1.5px;
	margin-left: 10px;
}

.notebook.add-notebook {
	box-shadow: none;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 5em;
	font-weight: 300;
	transition: all 0.3s ease;
	background: #ffffff40;
}

.notebook.add-notebook:hover {
	background: #ffffff;
	box-shadow: 0px 10px 40px -10px #1e365240;
}

.notebooks.collapse.hide:hover .add-notebook {
	background: #ecf1f4;
}

.notebook canvas {
	width: 100%;
	height: 100%;
	background: #111120;
	background-image: url(/assets/paper.png);
	background-size: 150px;
}

.notebook .info {
	color: #fff;
	position: absolute;
	bottom: 4%;
	left: 4%;
	width: calc(100% - 8%);
	transition: all 0.3s ease;
}

.notebooks.collapse .notebook .info h1 {
	font-size: 2em;
	text-align: left;
	letter-spacing: -0.05vw;
}

.notebooks.collapse .notebook .info {
	font-size: 0.5em;
	letter-spacing: -0.05em;
	bottom: 10px;
}

.notebooks.collapse .notebook .info {
	bottom: 10px;
}

.notebooks.collapse .notebook .info h1 {
	letter-spacing: -0.5px;
}

.notebook .info .num-sheets {
	float: left;
	font-size: 0.75vw;
	opacity: 0.5;
}

.notebook .info .open {
	float: right;
	color: rgb(255, 234, 111);
	font-size: 0.75vw;
}

.notebooks.collapse .notebook .info .num-sheets,
.notebooks.collapse .notebook .info .open {
	font-size: 0.75em;
}
/* remove the top border from cells. */
.table--read-only > div:first-of-type .cell {
	border-top: none;
}

.table--read-only > div:first-of-type {
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.table--read-only > div:last-of-type {
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.table--read-only .delete-row,
.table--read-only .cell--action:not(.preview) {
	display: none;
}

.table--read-only .cell:not(.cell--action),
.table--read-only .row:not(.header) .cell:not(.cell--action):hover + .cell {
	box-shadow: none !important;
}

.information-sidebar .cell--labels {
	margin-top: 5px;
}

.formatted-text--input[data-max-length="1"] {
	min-width: 1ch;
}

.formatted-text--input[data-max-length="2"] {
	min-width: 2ch;
}

.formatted-text--input[data-max-length="3"] {
	min-width: 3ch;
}

.formatted-text--input[data-max-length="4"] {
	min-width: 4ch;
}

.formatted-text--input[data-max-length="5"] {
	min-width: 5ch;
}

.formatted-text--input[data-max-length="6"] {
	min-width: 6ch;
}

.formatted-text--input[data-max-length="7"] {
	min-width: 7ch;
}

.formatted-text--input[data-max-length="8"] {
	min-width: 8ch;
}

.formatted-text--input[data-max-length="9"] {
	min-width: 9ch;
}

.formatted-text--input[data-max-length="10"] {
	min-width: 10ch;
}

.input {
	padding-bottom: 15px;
}

@media (max-width: 1400px) {
	.notebook {
		width: 36vw;
		height: 46.6vw;
		margin: 2vw;
	}

	.notebook .info h1 {
		font-size: 3vw;
	}

	.notebook .info .num-sheets,
	.notebook .info .open {
		font-size: 1vw;
	}
}

@media (max-width: 700px) {
	.notebook {
		width: 80vw;
		height: 103.5vw;
		margin: 20px 0;
	}
}

@media (max-width: 600px) {
	.table {
		margin: 0;
		height: 100vh;
	}
}
